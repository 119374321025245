import React, { useState, useEffect, memo } from 'react'
import {
    Card,
    Box,
    Select,
    MenuItem,
} from '@mui/material'
import Highlighter from "react-highlight-words";
import style from './DashboardCard.module.css'
import BookmarkElement from '../BookmarkElement/BookmarkElement';
import { SnackbarMessage } from '../../../interfaces/Snackbar' 
import DashboardTagElement from '../DashboardTagElement/DashboardTagElement';
import { DashboardPageThumbnail, DashboardGalleryThumbnail } from '../../../interfaces/DashboardMetadata';
import { getUserAccount } from '../../../utils/login';
import { bookmarkDashboard, unbookmarkDashboard } from '../../../services/data-connector';
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/store';
import { selectDashboardMetadata, selectDashboardStatus, dashboardMetadataAdded } from '../../../redux/features/dashboard/dashboardSlice';
import { STATUS } from '../../../variable/enum';
import { replaceWithPageName, updateDashboardMetadataBookmark } from '../../../utils/dashboard';

interface DashboardCardProps{
    padded?: boolean,
    dashboardMetadata: DashboardGalleryThumbnail
    searchText: string,
    activeTag?: string,
    dashboardType?: string,
    dataUpdateCycle?: string,
    dataType?: string,
    portType?: string,
    province?: string,
    portName?: string,
    setOpenSnackBar: React.Dispatch<boolean>,
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>,
    handleLoginPopUp: (link: string) => void,
    handleLoginPopUpFromBookmark: () => void,
    pathname: string
}

const DashboardCard = memo(({
    padded,
    dashboardMetadata, 
    searchText,
    activeTag,
    dashboardType,
    dataUpdateCycle,
    dataType,
    portType,
    province,
    portName,
    // for BookmarkElement only
    setOpenSnackBar, 
    setSnackPack,
    handleLoginPopUp,
    handleLoginPopUpFromBookmark,
    pathname
}: DashboardCardProps) => {
    const remove_undefined = (text:string|undefined):string => (text ? text : '')
    const isMultipleType = dashboardMetadata.galleryPages.length > 1
    const initialPageName = (dashboardMetadata.galleryPages.length > 0 && isMultipleType) ? dashboardMetadata.galleryPages[0].pageName! : ''
    const [pageName, setPageName] = useState<string>(initialPageName)
    const [dashboardPage, setDashboardPage] = useState<DashboardPageThumbnail>(dashboardMetadata.galleryPages[0])
    const [isBookmarked, setIsBookmarked] = useState<boolean>(dashboardPage.isBookmarked)
    const [openSelect, setOpenSelect] = useState<boolean>(false)
    const shortDescription = replaceWithPageName(dashboardMetadata.shortDescription, pageName)
    const pageTitle = dashboardMetadata.galleryTitle + ": " + dashboardPage.pageName
    const urlLink = dashboardPage.link
    const user = getUserAccount()

    useEffect(() => {
        if ((dashboardMetadata.galleryPages.length === 0 && isMultipleType)) {
            setPageName(initialPageName)
            setDashboardPage(dashboardMetadata.galleryPages[0])
            setIsBookmarked(dashboardMetadata.galleryPages[0].isBookmarked)
        } else if (dashboardMetadata.galleryPages.every((dashboardPage) => dashboardPage.pageName != pageName)) {
            setPageName(dashboardMetadata.galleryPages[0].pageName)
            setDashboardPage(dashboardMetadata.galleryPages[0])
            setIsBookmarked(dashboardMetadata.galleryPages[0].isBookmarked)
        } else if (padded && dashboardMetadata.galleryPages.find((dashboardPage) => dashboardPage.pageName === pageName)?.isBookmarked !== isBookmarked) {
            let dashboardPageTmp = dashboardMetadata.galleryPages.find((dashboardPage) => dashboardPage.pageName === pageName)
            if(dashboardPageTmp){
                setIsBookmarked(dashboardPageTmp.isBookmarked)
            }
        } 
    },[searchText, activeTag, dashboardType, dataUpdateCycle, dataType, portType, province, portName, dashboardMetadata])


    useEffect(() =>{
        let dashboardPageTmp = dashboardMetadata.galleryPages.find((dashboardPage) => dashboardPage.pageName === pageName)
        if(dashboardPageTmp){
            setDashboardPage(dashboardPageTmp)
            setIsBookmarked(dashboardPageTmp.isBookmarked)
        }
    },[pageName])

    const dispatch = useDispatch<AppDispatch>()
    const dashboardStatus = useSelector(selectDashboardStatus)
    const dashboardMetadatasFull = useSelector(selectDashboardMetadata)

    const setBookmarkInternal = (pageId: string) => {
        if(dashboardStatus === STATUS.SUCCEEDED){
            let dashboardMetadataTmp = updateDashboardMetadataBookmark(dashboardMetadatasFull, {pageId: pageId, isBookmarked: true})
            dispatch(dashboardMetadataAdded(dashboardMetadataTmp))
        }
    }

    const setUnbookmarkInternal = (pageId: string) => {
        if(dashboardStatus === STATUS.SUCCEEDED){
            let dashboardMetadataTmp = updateDashboardMetadataBookmark(dashboardMetadatasFull, {pageId: pageId, isBookmarked: false})
            dispatch(dashboardMetadataAdded(dashboardMetadataTmp))
        }
    }

    const DashboardCardElement = () => (
            <Card>
                <img src={dashboardMetadata.imgUrl} style={{width: '100%'}}></img>
                <div className={style.title}>
                    <Highlighter
                        sanitize={remove_undefined}
                        highlightStyle={{backgroundColor:'#bfdefc'}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={pageTitle}
                    />    
                    <BookmarkElement 
                    pageId={dashboardPage.pageId}
                    isBookmarked={isBookmarked}
                    setIsBookmarked={setIsBookmarked}
                    setOpenSnackBar={setOpenSnackBar}
                    setSnackPack={setSnackPack}
                    urlLink={urlLink}
                    bookmarkTooltipText={
                        isMultipleType ? 
                        <>กดเพื่อติดตามแดชบอร์ดหน้า<br /><span style={{fontWeight:'400', marginLeft: '5px', whiteSpace:'nowrap'}}>{dashboardPage.pageName}</span></>
                        :
                        "กดเพื่อติดตามแดชบอร์ดหน้านี้"
                    }
                    unbookmarkTooltipText="เลิกติดตามแดชบอร์ดนี้"
                    bookmarkSnackbarText={`ติดตามแดชบอร์ด "${pageTitle}" เรียบร้อยแล้ว`}
                    unbookmarkSnackbarText="เลิกติดตามแดชบอร์ดเรียบร้อยแล้ว"
                    callBookmarkAPI={bookmarkDashboard}
                    callUnbookmarkAPI={unbookmarkDashboard}
                    setBookmarkInternal={setBookmarkInternal}
                    setUnbookmarkInternal={setUnbookmarkInternal}
                    handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                    bookmarkIconStyle={{
                        position: 'absolute',
                        padding:0,
                        right:0,
                        top:0
                    }} />
                </div>
                
                <div style = {{textAlign:'left', gap:'5px', flexWrap:'wrap', display: 'flex'}}>
                    {
                        dashboardMetadata.dashboardType === 'privileged' ? 
                        <DashboardTagElement tag="แดชบอร์ดพิเศษ" pathname='/dashboard'/>
                        :
                        <></>
                    }
                    {
                        dashboardMetadata.tags.map((tag) => 
                            <DashboardTagElement key={tag} tag={tag} searchText={searchText} pathname={pathname} />
                        )
                    }
                </div>

                <div className={style.shortDescription}>
                    <Highlighter
                        sanitize={remove_undefined}
                        highlightStyle={{backgroundColor:'#bfdefc'}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={shortDescription}
                    />
                </div>
                <Box>

                    {
                        isMultipleType && dashboardMetadata.galleryPages ? 
                        <Select
                        onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOpenSelect(!openSelect)}}
                        open={openSelect}
                        value={pageName}
                        onChange={(e) => {
                            let pageNameTmp = e.target.value
                            setPageName(pageNameTmp)
                            let dashboardPageTmp = dashboardMetadata.galleryPages.find((dashboardPage) => dashboardPage.pageName === pageNameTmp)
                            if(dashboardPageTmp){
                                setDashboardPage(dashboardPageTmp)
                                setIsBookmarked(dashboardPageTmp.isBookmarked)
                            }
                            setOpenSelect(!openSelect)
                        }}
                        sx={{
                            width: padded ? {
                                md:'calc(33vw - 142px)', 
                                sm: 'calc(50vw - 178px)',
                                xs:'calc(87.64vw - 164.8202px)'
                            } : 
                            {
                                md:'calc(33vw - 102px)', 
                                sm: 'calc(50vw - 118px)',
                                xs:'calc(100vw - 159px)'
                            },
                            maxWidth:{
                                
                                md: padded ? '263.469px' : '303.321px'
                            }
                        }}
                        MenuProps={{
                            disableRestoreFocus: true
                        }}
                        >
                            {
                                dashboardMetadata.galleryPages.map((dashboardPage) => (
                                    <MenuItem
                                    key={dashboardPage.pageId}
                                    onClick={(e) => {
                                        e.preventDefault(); 
                                        e.stopPropagation();
                                        setOpenSelect(false)
                                    }} value={dashboardPage.pageName}>{dashboardPage.pageName}</MenuItem>
                                ))
                            }
                        </Select>
                        :
                        <Select
                        value={dashboardPage.pageName}
                        disabled
                        fullWidth
                        onClick={(e) => {e.preventDefault(); e.stopPropagation(); }}
                        >
                                    <MenuItem value={dashboardPage.pageName}>{dashboardPage.pageName}</MenuItem>
                        </Select>

                    }

                </Box>
            </Card>

    )
    
  return (
    <>
    {
        user.getIsLoggedIn() ? 
        <a href={urlLink} target='_blank'>
            <DashboardCardElement />
        </a>
        :
        <a onClick={() => handleLoginPopUp(dashboardPage.link)}>
            <DashboardCardElement />
        </a>
    }
    </>
  )
}
)

export default DashboardCard